<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <!-- Reset Password v1 -->
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="80" />
        </a>
        <h4 class="card-title mb-1">Set New Password 🔒</h4>
        <form class="auth-reset-password-form mt-2" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">New Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="passwordTextType ? 'text' : 'password'" formControlName="newPassword"
                class="form-control form-control-merge" placeholder="············" aria-describedby="reset-password-new"
                tabindex="1" autofocus [ngClass]="{ 'is-invalid error': submitted && f.newPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                        'icon-eye-off': passwordTextType,'icon-eye': !passwordTextType}" (click)="togglePasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.newPassword.errors }">
              <div *ngIf="f.newPassword.errors.required">Password is required</div>
              <div *ngIf="f.newPassword.errors?.pattern">Password must contain at least one number, one uppercase and a
                lowercase letter and at least 8 characters.</div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">Confirm Password</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input [type]="confPasswordTextType ? 'text' : 'password'" formControlName="confirmPassword"
                class="form-control form-control-merge" placeholder="············"
                aria-describedby="reset-password-confirm" tabindex="2"
                [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }" />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                        'icon-eye-off': confPasswordTextType,
                        'icon-eye': !confPasswordTextType
                      }" (click)="toggleConfPasswordTextType()"></i></span>
              </div>
            </div>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
              <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
              <div *ngIf="f.confirmPassword.errors?.pattern">Password must contain at least one number, one uppercase and a
                lowercase letter and at least 8 characters.</div>
            </div>
          </div>
          <div class="form-text text-danger" *ngIf="submitted && f.newPassword.value != f.confirmPassword.value">Please
            enter Password and Confirm Password should be same.</div>
          <div>
            <ul>
              <li>Password must have at least 1 lowercase.</li>
              <li> Password must have at least 1 uppercase.</li>
              <li> Password must have at least 1 numeric character.</li>
              <li>Password must have at least 1 special character.</li>
            </ul>
          </div>
          <button class="btn btn-primary btn-block" tabindex="3" rippleEffect>Set New Password</button>
        </form>
      </div>
    </div>
  </div>
</div>