<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
         <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
      </a>
    </li>
  </ul>
 </div>
<div class="navbar-container d-flex content">
   <ul class="nav navbar-nav align-items-center ml-auto">
     <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"  ngbDropdownToggle  id="navbarUserDropdown"  aria-haspopup="true"
        aria-expanded="false">
        <ng-container *ngIf="currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{ userData?.FirstName }} {{ userData?.LastName }}</span
            ><span class="user-status">{{ rotetype }}</span>
          </div>
          <span class="avatar">
            <img class="round" src="assets/images/portrait/small/user.jpg"  alt="avatar"
              height="40" width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
          <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
  </ul>
</div>