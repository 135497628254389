import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;
  private currentUserRole: BehaviorSubject<boolean>;
  public userData: BehaviorSubject<any>;
  public userDataLogin: BehaviorSubject<any>
  // appInsights: ApplicationInsights;

  /**
  *
  * @param {HttpClient} _http
  * @param {ToastrService} _toastrService
  */
  constructor(private _http: HttpClient, private _router: Router, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserRole = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('currentUserRole')));
    this.userData = new BehaviorSubject<any>([]);
    // this.appInsights = new ApplicationInsights({
    //   config: {
    //     instrumentationKey: environment.appInsights.instrumentationKey,
    //     enableAutoRouteTracking: true // option to log all route changes
    //   }
    // });
    // this.appInsights.loadAppInsights();

  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  getValue(): Observable<boolean> {
    return this.currentUserRole.asObservable();
  }
  setValue(newValue): void {
    this.currentUserRole.next(newValue);
  }

  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  // Tracking loging error with Azure

  // logPageView(name?: string, url?: string) { // option to call manually
  //   this.appInsights.trackPageView({
  //     name: name,
  //     uri: url
  //   });
  // }

  // logEvent(name: string, properties?: { [key: string]: any }) {
  //   this.appInsights.trackEvent({ name: name}, properties);
  // }

  // logMetric(name: string, average: number, properties?: { [key: string]: any }) {
  //   this.appInsights.trackMetric({ name: name, average: average }, properties);
  // }

  // logException(exception: Error, severityLevel?: number) {
  //   this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  // }

  // logTrace(message: string, properties?: { [key: string]: any }) {
  //   this.appInsights.trackTrace({ message: message}, properties);
  // }
  
  /**
   * User login
   *
   * @param email
   * @param password
   * @param UserEmailId
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/token`, { Username: email, Password: password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.ResponseData && user.ResponseData.Token.Token) {
            user.token = user.ResponseData.Token.Token;
            user.role = 'Admin';
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            this._router.navigate(['worklogs']);
            this.getUserDetails().subscribe(res => {
              localStorage.setItem('user_role', res.ResponseData.RoleName[0])
              localStorage.setItem('userData', JSON.stringify(res.ResponseData))
              localStorage.setItem('userId', res.ResponseData.UserId);
              this.userData.next(res.ResponseData);       
              res.ResponseData.RoleTypeIds.forEach(data => {
                if (data == 3 || data == 1 || data == 2 || data == 4 || data == 5) {
                  localStorage.setItem('currentUserRole', 'true');
                  localStorage.setItem('showReviewCheck', 'true');
                  this.setValue(true)
                } else if (data == 9) {
                  this.setValue(false)
                  localStorage.setItem('currentUserRole', 'false');
                  localStorage.setItem('showReviewCheck', 'false');
                } else {
                  this.setValue(false)
                  localStorage.setItem('currentUserRole', 'false');
                  localStorage.setItem('showReviewCheck', 'true');
                }
              })
            })
          } else { }
          return user;
        })
      );
  }

  forgotpassowrd(email: string) {
    return this._http.post<any>(`${environment.apiUrl}/auth/forgetpassword`, { UserEmailId: email })
  }

  getUserDetails(): Observable<any> {
    return this._http.get<User[]>(`${environment.apiUrl}/auth/getuserdetail`);
  }

  logoutDetails(json) {  
    // remove user from local storage to log user out
    return this._http.post<any>(`${environment.apiUrl}/auth/logout`, json).pipe(
      map(res => {
        this.currentUserSubject.next(null);
        return res;
      })
    );
  }

  setPassword(json) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/setpassword`, json)
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.ResponseData && user.ResponseData.Token.Token) {
          } else { }
          return user;
        })
      );
  }

  checkValidate(json) {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/validatetoken`, json)
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          return user;
        })
      );
  }
}